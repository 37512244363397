import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static values = {
    workInteractionId: String,
  };

  copy(event) {
    event.preventDefault();
    trackEventWithDataToAmplitudeAndGoogle(
      "Copy",
      "Transcript",
      "Transcript - Copy",
      { query: this.workInteractionIdValue },
    );
  }

  copyVideoUrl(event) {
    event.preventDefault();
    trackEventWithDataToAmplitudeAndGoogle(
      "Copy",
      "Transcript",
      "Transcript - Copy Video Url",
      { query: this.workInteractionIdValue },
    );
  }

  share() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Share",
      "Transcript",
      "Transcript - Share",
      { query: this.workInteractionIdValue },
    );

    $("#shareWith").modal("show");
  }

  playVideoClicked() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Play Video",
      "Meeting Overview",
      "Meeting Overview - Play Video",
      { query: this.workInteractionIdValue },
    );
  }
}
