import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["lightThemeButton", "darkThemeButton", "systemThemeButton"];

  connect() {
    this.setTheme();
  }

  /* eslint-disable class-methods-use-this */
  onClick() {
    // Closes the sidebar when user clicks outside the sidebar, thus clicking on the overlay
    // The vertical-overlay is the overlay that appears when the sidebar is open
    document.body.classList.remove("vertical-sidebar-enable");
    document.querySelector(".hamburger-icon").classList.remove("open");
  }
  /* eslint-enable class-methods-use-this */

  setTheme() {
    // Sets the color theme based on the user's preference
    let theme = localStorage.getItem("data-bs-theme");
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    this.lightThemeButtonTarget.classList.remove("active");
    this.darkThemeButtonTarget.classList.remove("active");
    this.systemThemeButtonTarget.classList.remove("active");
    if (theme === "dark") {
      this.darkThemeButtonTarget.classList.add("active");
    } else if (theme === "light") {
      this.lightThemeButtonTarget.classList.add("active");
    } else {
      this.systemThemeButtonTarget.classList.add("active");
    }

    if (theme === null || theme === "system") {
      theme = darkMediaQuery.matches ? "dark" : "light";
    }

    document.documentElement.setAttribute("data-bs-theme", theme);
  }

  setThemePreference(theme) {
    localStorage.setItem("data-bs-theme", theme);
    this.setTheme();
    return false;
  }

  setLightTheme() {
    return this.setThemePreference("light");
  }

  setDarkTheme() {
    return this.setThemePreference("dark");
  }

  setSystemTheme() {
    return this.setThemePreference("system");
  }
}
