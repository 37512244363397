import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hamburgerMenu", "hamburgerMenuIcon"];

  connect() {
    const menuState = localStorage.getItem("menu-state");
    if (menuState) {
      this.setSideBarState(menuState);
    }
  }

  onHamburgerMenuClick() {
    const isOpen = this.hamburgerMenuTarget.classList.toggle("open");
    localStorage.setItem("menu-state", isOpen ? "open" : "close");
    this.setSideBarState(isOpen ? "open" : "close");
  }

  setSideBarState(menuState) {
    const windowSize = document.documentElement.clientWidth;
    this.hamburgerMenuIconTarget.innerText = `left_panel_${menuState}`;
    if (menuState === "open") {
      this.hamburgerMenuTarget.classList.add("open");
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    } else {
      this.hamburgerMenuTarget.classList.remove("open");
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
  }
}
